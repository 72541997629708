<script>
import Layout from '@layouts/main.vue'
import LoadingView from './_loading.vue'

export default {
  page: {
    title: 'Page timeout',
    meta: [
      { name: 'description', content: 'The page timed out while loading.' },
    ],
  },
  components: { Layout, LoadingView },
  data() {
    return {
      offlineConfirmed: false,
    }
  },
}
</script>

<template>
  <Layout v-if="offlineConfirmed">
    <div class="page login_page d-flex align-items-center flex-column justify-content-between">
      <div class="page_wrap text-center w-100">
        <div class="login-header d-flex align-items-center justify-content-center mb-3">
          <div class="logo"></div>
          <h1 class="bg-white br-12 clr">Геоэффект</h1>
        </div>
        <div class="d-flex flex-column">
          <div class="form-header"></div>
          <div class="form-body d-flex flex-column mb-3">
            <h3>Кажется, что-то не так...</h3>
            <p class="text-center">Интернет соединение не стабильно или отсутствует.</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  <LoadingView v-else />
</template>

<!--<style lang="scss">-->
<!--  //@import '@design/login_page.scss';-->
<!--</style>-->