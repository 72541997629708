<script>
import Layout from '@layouts/main.vue'

export default {
  page: {
    title: 'Loading page...',
    meta: [{ name: 'description', content: 'Loading page...' }],
  },
  components: { Layout },
}
</script>

<template>
  <Layout>
    <div>
      <h1>Загрузка</h1>
    </div>
  </Layout>
</template>

<style lang="scss" module>

</style>
