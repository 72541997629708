<script>
import appConfig from '@src/app.config'

export default {
    data: function () {
        return {
            isPrintVersion: false
        }
    },
    page: {
        // All subcomponent titles will be injected into this template.
        titleTemplate(title) {
            title = typeof title === 'function' ? title(this.$store) : title
            return title ? `${title} | ${appConfig.title}` : appConfig.title
        },
    }
}
</script>

<template>
    <div id="app">
        <!--
        Even when routes use the same component, treat them
        as distinct and create the component again.
        -->
        <RouterView :key="$route.fullPath"/>
    </div>
</template>

<style lang="scss">

</style>
