import Vue from 'vue'
import router from '@router'
import store from '@state/store'
import App from './app.vue'

(async function () {
    const app = new Vue({
        router,
        store,
        render: (h) => h(App)
    }).$mount('#app');
})(window);