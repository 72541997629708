<script>
  export default {

  }
</script>

<template>
  <div class="page-wrapper container-fluid">
    <slot />
  </div>
</template>

<style lang="scss">
  @import '@design';
</style>


