import Vue from 'vue'
import Vuex from 'vuex'
import dispatchActionForAllModules from '@utils/dispatch-action-for-all-modules'

import modules from './modules'
import _ from "lodash";

Vue.use(Vuex)

const store = new Vuex.Store({
    modules,
    // Enable strict mode in development to get a warning
    // when mutating state outside of a mutation.
    // https://vuex.vuejs.org/guide/strict.html
    strict: process.env.NODE_ENV !== 'production'
});

// Сохраняю в localStorage избранные данные. Укажи массив ключей в state.needSave модуля
// Проблема в ограничение 5 МБ, хранить можно только самое необходимое.
store.subscribe((mutation, state) => {
    const moduleName = mutation.type.split('/')[0];
    const moduleState = state[moduleName];

    // Игнорирую для модуля, т.к. в нем другое решение
    if (moduleName === 'filter') {
        return;
    }

    if (moduleState.needSave && moduleState.needSave.length) {
        const moduleStateToSave = _.pickBy(moduleState, function(value, stateDataProperty) {
            return moduleState.needSave.indexOf(stateDataProperty) > -1;
        });
        // Строю имя для хранилища с учетом суффикса
        const storeName = [
            'store',
            moduleName
        ];
        localStorage.setItem(storeName.join('.'), JSON.stringify(moduleStateToSave));
    }
})

export default store

// Automatically run the `init` action for every module,
// if one exists.
dispatchActionForAllModules('init');

// Вызываю метод loadFromLocalStorage в каждом store.* модуле
dispatchActionForAllModules('loadFromLocalStorage');
